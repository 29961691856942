<template>
  <div>
    <validation-provider
      #default="validationContext"
      :name="label"
      :rules="validationRules"
    >
      <b-form-group
        :label="label"
        :label-for="`${label.replace(' ', '')}-select`"
      >
        <b-input-group>
          <v-select
            :id="`${label.replace(' ', '')}-select`"
            v-model="selectedValue"
            class="width-v-select"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :disabled="disabled"
            :label="labelSelect"
            :options="options"
            :reduce="reduce"
            @search="searchModal"
            @input="changeValue"
          />
          <b-input-group-append class="w-7">
            <b-button
              variant="primary"
              @click="callModal"
            >
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
    <b-modal
      id="modalAdd"
      ref="modalAdd"
      :title="modalTitle"
      size="lg"
      :hide-footer="true"
    >
      <component
        :is="modal"
        :modal-call="true"
        @find-records="searchModal"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { ref, computed } from '@vue/composition-api'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    vSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },
    validationRules: {
      type: String,
      default: '',
      require: false,
    },
    label: {
      type: String,
      require: false,
      default: 'Field name',
    },
    labelSelect: {
      type: String,
      require: false,
      default: 'texto',
    },
    options: {
      type: Array,
      require: true,
      default: () => [],
    },
    reduce: {
      type: Function,
      require: false,
      default: option => option.id,
    },
    additionalValidation: {
      type: Boolean,
      require: false,
      default: false,
    },
    additionalValidationText: {
      type: String,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    modal: {
      type: Function,
      require: true,
      default: () => {},
    },
    modalTitle: {
      type: String,
      require: false,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    // eslint-disable-next-line vue/require-prop-types
    getValidationState: {
      default: () => {},
    },
  },
  computed: {
    selectedValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.changeValue(newValue)
      },
    },
  },
  setup(props, { emit }) {
    const modalAdd = ref(null)
    const callModal = () => {
      modalAdd.value.show()
    }
    const searchModal = () => {
      emit('search')
      modalAdd.value.hide()
    }
    const changeValue = newValue => {
      emit('input', newValue)
    }
    return {
      modalAdd,
      callModal,
      searchModal,
      changeValue,
      computed,
    }
  },
}

</script>
