import defaultService from './_default.service'

export default function organizationChartService() {
  const defaultMethods = defaultService('Organigrama')
  // ===================================================================|
  //  HierarchicalLevels-------------------------------------------------|
  // ===================================================================|
  const fetchHierarchicalLevels = (data, callback) => defaultMethods.fetch('NivelJerarquico', {}, data, callback)
  const fetchHierarchicalLevel = (id, callback) => defaultMethods.fetch('NivelJerarquico/%{id}', { id }, {}, callback)
  const createHierarchicalLevel = (data, callback) => defaultMethods.create('NivelJerarquico', {}, true, data, callback)
  const updateHierarchicalLevel = (id, data, callback) => defaultMethods.update('NivelJerarquico/%{id}', { id }, true, data, callback)
  const deleteHierarchicalLevel = (id, callback) => defaultMethods.remove('NivelJerarquico/%{id}', { id }, true, callback)

  // ===================================================================|
  //  Areas-------------------------------------------------|
  // ===================================================================|
  const fetchAreas = (data, callback) => defaultMethods.fetch('Area', {}, data, callback)
  const fetchArea = (id, callback) => defaultMethods.fetch('Area/%{id}', { id }, {}, callback)
  const createArea = (data, callback) => defaultMethods.create('Area', {}, true, data, callback)
  const updateArea = (id, data, callback) => defaultMethods.update('Area/%{id}', { id }, true, data, callback)
  const deleteArea = (id, callback) => defaultMethods.remove('Area/%{id}', { id }, true, callback)

  return {
    // Employee
    fetchHierarchicalLevels,
    fetchHierarchicalLevel,
    createHierarchicalLevel,
    updateHierarchicalLevel,
    deleteHierarchicalLevel,
    // Areas
    fetchAreas,
    fetchArea,
    createArea,
    updateArea,
    deleteArea,

  }
}
