<template>
  <b-card no-body class="p-2">
    <h3 class="mb-2">
      {{
        job.puestoId == 0
          ? $t("Routes.JobAdd")
          : $t("Routes.JobEdit")
      }}
    </h3>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work area field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.job.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.job.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input v-model="job.nombre" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <v-select-add
              v-model="job.areaId"
              validation-rules="required"
              :label="$t('humanResources.job.columns.area')"
              label-select="nombre"
              :options="areas"
              :reduce="(option) => option.areaId"
              :get-validation-state="getValidationState"
              :modal="areaAdd"
              :modal-title="$t('Routes.ProfileSalaryAdd')"
              @search="searchAreas"
            />
          </b-col>
          <b-col cols="12" md="6">
            <v-select-add
              v-model="job.claseContratoId"
              validation-rules="required"
              :label="$t('humanResources.job.columns.classContract')"
              :options="contractClasses"
              :reduce="(option) => option.id"
              :get-validation-state="getValidationState"
              :modal="classContractAdd"
              :modal-title="$t('Routes.ClassContractAdd')"
              @search="getClassContract"
            />
          </b-col>
          <b-col cols="12" md="6">
            <v-select-add
              v-model="job.dedicacionLaboralId"
              validation-rules="required"
              :label="$t('humanResources.job.columns.workDedication')"
              :options="workDedications"
              :get-validation-state="getValidationState"
              :modal="laboralDedicationAdd"
              :modal-title="$t('Routes.LaboralDedicationAdd')"
              @search="getLaboralDedications"
            />
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.job.columns.profile')"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.job.columns.profile')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="usuario"
                  v-model="job.perfilId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :options="profiles"
                  :reduce="(option) => option.perfilId"
                  @search="searchProfiles"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <v-select-add
              v-model="job.perfilSalarialId"
              validation-rules="required"
              :label="$t('humanResources.job.columns.profileSalary')"
              label-select="nombre"
              :options="salaryProfiles"
              :reduce="(option) => option.perfilSalarialId"
              :get-validation-state="getValidationState"
              :modal="profileSalaryAdd"
              :modal-title="$t('Routes.ProfileSalaryAdd')"
              @search="searchProfileSalarys"
            />
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.job.columns.answerJob')"
            >
              <b-form-group
                :label="$t('humanResources.job.columns.answerJob')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="usuario"
                  v-model="job.puestoRespondeAId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :options="jobs"
                  :reduce="(option) => option.puestoId"
                  @search="searchJobs"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <v-select-add
              v-model="job.esquemaPagoTipoId"
              validation-rules="required"
              :label="$t('humanResources.job.columns.schemeTypePayment')"
              :options="schemePaymentTypes"
              :get-validation-state="getValidationState"
              :modal="schemeTypeModalAdd"
              :modal-title="$t('Routes.SchemeTypePaymentAdd')"
              @search="getSchemeTypePayment"
            />
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('humanResources.job.columns.paymentTypePeriod')"
            >
              <b-form-group
                :label="$t('humanResources.job.columns.paymentTypePeriod')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="usuario"
                  v-model="job.periodoPagoTipoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="texto"
                  :options="paymentPeriodTypes"
                  :reduce="(option) => option.id"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Description Field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('humanResources.job.columns.description')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="job.descripcion" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingJob" small class="mr-1" />
            {{ job.puestoId == 0 ? $t("Lists.Add") : $t("Lists.Edit") }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BSpinner,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import humanResources from '@/services/humanresources.service'
import optionService from '@/services/option.service'
import organizationChart from '@/services/organizationchart.service'
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'
import VSelectAdd from '@/components/VSelectAdd.vue'
import { required } from '@validations'
import router from '@/router'
import { ref, defineComponent } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BButton,
    BSpinner,

    ValidationProvider,
    ValidationObserver,

    vSelect,
    VSelectAdd,
  },
  setup() {
    // Data
    const areas = ref([])
    const areaModal = ref(null)
    const classContractModal = ref(null)
    const contractClasses = ref([])
    const workDedications = ref([])
    const laboralDedicationModal = ref(null)
    const profiles = ref([])
    const salaryProfiles = ref([])
    const profileSalaryModal = ref(null)
    const jobs = ref([])
    const schemePaymentTypes = ref([])
    const schemeTypeModal = ref(null)
    const paymentPeriodTypes = ref([])
    const addingJob = ref(false)
    const job = ref({
      puestoId: 0,
      areaId: 0,
      claseContratoId: 0,
      dedicacionLaboralId: 0,
      perfilId: 0,
      perfilSalarialId: 0,
      puestoRespondeAId: null,
      esquemaPagoTipoId: null,
      periodoPagoTipoId: null,
      nombre: '',
      descripcion: '',
      archivado: false,
    })

    const blankJob = {
      puestoId: 0,
      areaId: 0,
      claseContratoId: 0,
      dedicacionLaboralId: 0,
      perfilId: 0,
      perfilSalarialId: 0,
      puestoRespondeAId: null,
      esquemaPagoTipoId: null,
      periodoPagoTipoId: null,
      nombre: '',
      descripcion: '',
      archivado: false,
    }
    // Api calls
    const {
      fetchJob,
      createJob,
      updateJob,
      fetchProfiles,
      fetchProfilesSalary,
      fetchJobs,
    } = humanResources()

    const {
      fetchOptions,
    } = optionService()

    const {
      fetchAreas,
    } = organizationChart()

    // Modals
    const profileSalaryAdd = defineComponent(() => import('../profile-salary/ProfileSalaryAddEdit.vue'))
    const areaAdd = defineComponent(() => import('../../organization-chart/area/AreaAddEdit.vue'))
    const classContractAdd = defineComponent(() => import('../class-contract/ClassContractAddEdit.vue'))
    const laboralDedicationAdd = defineComponent(() => import('../laboral-dedication/LaboralDedicationAddEdit.vue'))
    const schemeTypeModalAdd = defineComponent(() => import('../scheme-type-payment/SchemeTypePaymentAddEdit.vue'))
    const resetJob = () => {
      job.value = JSON.parse(JSON.stringify(blankJob))
    }
    if (router.currentRoute.params.puestoId) {
      fetchJob(router.currentRoute.params.puestoId, data => {
        job.value = data
      })
    } else {
      job.value = JSON.parse(JSON.stringify(blankJob))
    }
    fetchOptions({ nombreDominio: 'RecursosHumanosClaseContrato' }, data => {
      contractClasses.value = data
    })
    const getClassContract = () => {
      fetchOptions({ nombreDominio: 'RecursosHumanosClaseContrato' }, data => {
        contractClasses.value = data
      })
    }
    fetchOptions({ nombreDominio: 'RecursosHumanosDedicacionLaboral' }, data => {
      workDedications.value = data
    })
    const getLaboralDedications = () => {
      fetchOptions({ nombreDominio: 'RecursosHumanosDedicacionLaboral' }, data => {
        workDedications.value = data
      })
    }
    const getSchemeTypePayment = () => {
      fetchOptions({ nombreDominio: 'RecursosHumanosEsquemaPagoTipo' }, data => {
        schemePaymentTypes.value = data
      })
    }
    fetchOptions({ nombreDominio: 'RecursosHumanosEsquemaPagoTipo' }, data => {
      schemePaymentTypes.value = data
    })
    fetchOptions({ nombreDominio: 'RecursosHumanosPeriodoPagoTipo' }, data => {
      paymentPeriodTypes.value = data
    })
    const searchAreas = text => {
      fetchAreas({ searchQuery: text, limit: 50 }, data => {
        areas.value = data.areas
      })
    }
    fetchAreas({ limit: 50 }, data => {
      areas.value = data.areas
    })
    const searchJobs = text => {
      fetchJobs({ searchQuery: text, limit: 50 }, data => {
        jobs.value = data.puestos
      })
    }
    fetchJobs({ limit: 50 }, data => {
      jobs.value = data.puestos
    })
    const searchProfiles = text => {
      fetchProfiles({ searchQuery: text, limit: 50 }, data => {
        profiles.value = data.perfiles
      })
    }
    fetchProfiles({ limit: 50 }, data => {
      profiles.value = data.perfiles
    })
    const searchProfileSalarys = text => {
      fetchProfilesSalary({ searchQuery: text, limit: 50 }, data => {
        salaryProfiles.value = data.perfilesSalariales
      })
    }
    fetchProfilesSalary({ limit: 50 }, data => {
      salaryProfiles.value = data.perfilesSalariales
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetJob)

    const onSubmit = () => {
      addingJob.value = true
      if (job.value.puestoId === 0) {
        createJob(job.value, () => {
          addingJob.value = false
          router.push({ name: 'apps-human-resources-job-list' })
        })
      } else {
        updateJob(job.value.puestoId, job.value, () => {
          addingJob.value = false
          router.push({ name: 'apps-human-resources-job-list' })
        })
      }
    }

    return {
      // Data
      areas,
      areaModal,
      contractClasses,
      classContractModal,
      workDedications,
      laboralDedicationModal,
      profiles,
      salaryProfiles,
      profileSalaryModal,
      jobs,
      schemePaymentTypes,
      schemeTypeModal,
      paymentPeriodTypes,
      job,
      addingJob,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      // Components
      areaAdd,
      profileSalaryAdd,
      classContractAdd,
      schemeTypeModalAdd,
      laboralDedicationAdd,
      // Functions
      onSubmit,
      searchProfiles,
      searchProfileSalarys,
      searchAreas,
      searchJobs,
      getClassContract,
      getLaboralDedications,
      getSchemeTypePayment,

    }
  },
}
</script>
